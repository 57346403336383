import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Spinner, Image, Card, Form, Row, Col, Modal } from 'react-bootstrap';
import { FaCogs, FaTasks, FaUsers, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';
import HeroImage from './assets/hero_image.png';

const UserSelectionPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [showWaitlist, setShowWaitlist] = useState(false); // Toggle for waitlist visibility
  const [showTermsModal, setShowTermsModal] = useState(false); // Toggle for Terms and Conditions Modal
  const navigate = useNavigate();

  const handleCognitoSignIn = () => {
    const domain = process.env.REACT_APP_COGNITO_DOMAIN;
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_COGNITO_REDIRECT_URI;
    const loginUrl = `https://${domain}/login?client_id=${clientId}&response_type=token&scope=email+openid+profile&redirect_uri=${redirectUri}`;
    window.location.href = loginUrl;
  };

  const handleIdToken = (idToken) => {
    try {
      const decodedIdToken = jwtDecode(idToken);
      const userSessionId = decodedIdToken.sub;
      const username = decodedIdToken['cognito:username'] || decodedIdToken['preferred_username'];
      const podName = decodedIdToken['cognito:groups'] ? decodedIdToken['cognito:groups'][0] : null;

      localStorage.setItem('idToken', idToken);
      localStorage.setItem('userSessionId', userSessionId);
      localStorage.setItem('username', username);
      if (podName) {
        localStorage.setItem('podName', podName);
      }

      setIsAuthenticated(true);
      setLoading(false);
      navigate('/home');
    } catch (error) {
      console.error('Error handling id_token:', error);
      setErrorMessage('Failed to authenticate. Please try again.');
      setIsAuthenticated(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const idToken = hashParams.get('id_token');

    if (idToken) {
      handleIdToken(idToken);
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, [navigate]);

  const handleWaitlistSubmit = () => {
    if (!name || !email || !role) {
      alert('Please fill out all fields to join the waitlist.');
    } else {
      alert('Thank You! You have been added to the waitlist. We will be in touch soon!');
      setName('');
      setEmail('');
      setRole('');
    }
  };

  return (
    <Container fluid style={{ padding: 0, backgroundColor: '#121212' }}>
      {/* Hero Section */}
      <div style={{ position: 'relative', backgroundColor: '#121212' }}>
        <Image src={HeroImage} style={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }} alt="Hero" />
      </div>

      {/* Intro Section */}
      <Container style={{ textAlign: 'center', padding: '2rem 0', backgroundColor: '#121212', color: '#FFFFFF' }}>
        <h1 style={{ color: '#FFFFFF', fontWeight: 'bold' }}>Welcome to Level Up In Tech Labs</h1>
        <p style={{ fontSize: '1.2rem', color: '#CCCCCC' }}>Hands-on AWS Cloud Labs designed for real-world challenges.</p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
          {showWaitlist && (
            <Button
              onClick={() => document.getElementById('waitlist').scrollIntoView({ behavior: 'smooth' })}
              style={{ backgroundColor: '#E50A7A', borderColor: '#E50A7A', color: '#FFFFFF', fontSize: '1.2rem', padding: '0.75rem 1.5rem' }}
            >
              Join the Waitlist
            </Button>
          )}
          <Button
            onClick={handleCognitoSignIn}
            style={{ backgroundColor: '#E50A7A', borderColor: '#E50A7A', color: '#FFFFFF', fontSize: '1.2rem', padding: '0.75rem 1.5rem' }}
          >
            Member Sign In
          </Button>
        </div>
      </Container>

      {/* Benefits Section */}
      <Container style={{ padding: '3rem 0', color: '#FFFFFF', backgroundColor: '#121212' }}>
        <Row>
          <Col md={4} style={{ textAlign: 'center', marginBottom: '1rem' }}>
            <FaCogs size={50} style={{ color: '#E50A7A', marginBottom: '1rem' }} />
            <h4 style={{ color: '#E50A7A' }}>Challenge Labs</h4>
            <p>Troubleshoot pre-configured environments with real-world errors to sharpen your skills.</p>
          </Col>
          <Col md={4} style={{ textAlign: 'center', marginBottom: '1rem' }}>
            <FaTasks size={50} style={{ color: '#E50A7A', marginBottom: '1rem' }} />
            <h4 style={{ color: '#E50A7A' }}>Assessment Labs</h4>
            <p>Start from scratch and build solutions to meet customer requirements while mastering core concepts.</p>
          </Col>
          <Col md={4} style={{ textAlign: 'center', marginBottom: '1rem' }}>
            <FaUsers size={50} style={{ color: '#E50A7A', marginBottom: '1rem' }} />
            <h4 style={{ color: '#E50A7A' }}>Pod Collaboration Labs</h4>
            <p>Work collaboratively on group projects to tackle complex real-world scenarios.</p>
          </Col>
        </Row>
      </Container>

      {/* Waitlist Section */}
      {showWaitlist && (
        <Container id="waitlist" style={{ padding: '2rem', maxWidth: '600px', textAlign: 'center', backgroundColor: '#1E1E1E', color: '#FFFFFF', borderRadius: '8px' }}>
          <Card style={{ border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
            <Card.Body>
              <h3>Join the Waitlist</h3>
              <p>
                Currently, our labs are only available to Level Up In Tech members. Sign up to be the first to know when they open to everyone!
              </p>
              <Form>
                <Form.Group controlId="name" style={{ marginBottom: '1rem' }}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="email" style={{ marginBottom: '1rem' }}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="role" style={{ marginBottom: '1rem' }}>
                  <Form.Label>Current Role</Form.Label>
                  <Form.Control
                    as="select"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="">Select your role</option>
                    <option value="Developer">Developer</option>
                    <option value="DevOps Engineer">DevOps Engineer</option>
                    <option value="IT Administrator">IT Administrator</option>
                    <option value="Student">Student</option>
                    <option value="Aspiring Cloud Professional">Aspiring Cloud Professional</option>
                  </Form.Control>
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={handleWaitlistSubmit}
                  style={{
                    width: '100%',
                    backgroundColor: '#E50A7A',
                    borderColor: '#E50A7A',
                    color: '#FFFFFF',
                  }}
                >
                  Join Waitlist
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      )}

      {/* Footer Section */}
      <footer style={{ backgroundColor: '#121212', color: '#FFFFFF', padding: '1rem 0', textAlign: 'center', marginTop: '2rem' }}>
        <div>
          <a href="https://www.linkedin.com/in/levelupwithbroadus/" target="_blank" rel="noopener noreferrer" style={{ color: '#E50A7A', margin: '0 1rem' }}>
            <FaLinkedin size={30} />
          </a>
          <a href="https://www.instagram.com/mrlevelupintech/" target="_blank" rel="noopener noreferrer" style={{ color: '#E50A7A', margin: '0 1rem' }}>
            <FaInstagram size={30} />
          </a>
          <a href="https://levelupintech.com" target="_blank" rel="noopener noreferrer" style={{ color: '#E50A7A', margin: '0 1rem' }}>Contact</a>
          <a href="#" onClick={() => setShowTermsModal(true)} style={{ color: '#E50A7A', margin: '0 1rem' }}>Terms and Conditions</a>
        </div>
        <p style={{ marginTop: '1rem' }}>© {new Date().getFullYear()} Level Up In Tech. All rights reserved.</p>
      </footer>

      {/* Terms and Conditions Modal */}
      <Modal show={showTermsModal} onHide={() => setShowTermsModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Welcome to Level Up In Tech Labs! Please read our terms carefully:</p>
          <ul>
            <li>Use the lab environments responsibly; no malicious or unauthorized activities.</li>
            <li>We are not responsible for errors caused by user actions.</li>
            <li>Comply with AWS guidelines and terms of use.</li>
            <li>Your data is stored securely and will not be shared without consent.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTermsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserSelectionPage;
